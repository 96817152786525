import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';

const NikkeGuidesCollectionPriority: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page char-guide wishlist-nikke'}
      game="nikke"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Collection Items</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_priority.jpg"
            alt="Liberation"
          />
        </div>
        <div className="page-details">
          <h1>Collection Items - Priority</h1>
          <h2>
            A priority guide for the Collection Items to help you decide who to
            equip the Dolls on.
          </h2>
          <p>
            Last updated: <strong>21/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          <i>
            "Who should I equip my dolls on? Which Nikke's doll should I upgrade
            first? Should I equip R dolls first or SR dolls first on this
            Nikke?"
          </i>
        </p>
        <p>
          This guide aims to answer the above questions as best as possible! We
          will be dividing these answers into two simple parts. The first part
          will cover which Nikke should get the Doll first, The second part will
          cover which Nikke you should spend your Maintenance Kits to upgrade it
          to the top level! So, without further ado, let's get into it!
        </p>
        <SectionHeader title="Equipping Priority" />
        <p>
          This section will cover which Nikkes you should equip your Weapon
          Based Dolls on first. We will be taking both PvE and PvP into
          consideration, and will have a separate priority tier for PvP Nikkes
          for each Weapon Type!
        </p>
        <h5>Sniper Rifle</h5>
        <div className="wishlist-table ">
          <div className="column">
            <h5 className="prio-1">DPS</h5>
            <ol className="list-with-chars-inline">
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="red-hood"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="alice"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="maxwell"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="ein"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="exia"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-3">Support</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="d-killer-wife"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="exia"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="mari-makinami-illustrious"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="frima"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="helm"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="bay-goddess-mary"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="sakura"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="yulha"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="dolla"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-2">PVP</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="red-hood"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="alice"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="ein"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="nihilister"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="harran"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="maxwell"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="sakura"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <h5>Machine Gun</h5>
        <div className="wishlist-table ">
          <div className="column">
            <h5 className="prio-1">DPS</h5>
            <ol className="list-with-chars-inline">
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="modernia"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="ludmilla-winter-owner"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="rei-ayanami"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="guillotine"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-3">Support</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="crown"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="diesel"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="rem"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="elegg"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-2">PVP</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="rosanna"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="emma"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <h5>Assault Rifle</h5>
        <div className="wishlist-table ">
          <div className="column">
            <h5 className="prio-1">DPS</h5>
            <ol className="list-with-chars-inline">
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="snow-white"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="asuka-shikinami-langley"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="scarlet"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="sakura-bloom-in-summer"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="2b"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="phantom"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-3">Support</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="dorothy"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="blanc"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="tove"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="rosanna-chic-ocean"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="aqua-marine-helm"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="privaty"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="ade"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-2">PVP</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="scarlet"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="blanc"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="moran"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="sakura-bloom-in-summer"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="2b"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="folkwang"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <h5>Shotgun</h5>
        <div className="wishlist-table ">
          <div className="column">
            <h5 className="prio-1">DPS</h5>
            <ol className="list-with-chars-inline">
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="soda-twinkling-bunny"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="privaty-unkind-maid"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="sparkling-summer-anis"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="sugar"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="guilty"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="viper"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="drake"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-3">Support</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="naga"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noir"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="leona"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="marciana"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="pepper"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="poli"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-2">PVP</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="sparkling-summer-anis"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="soda-twinkling-bunny"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="privaty-unkind-maid"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="maiden"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="pepper"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="drake"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="sugar"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="viper"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="marciana"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="neon"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="poli"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <h5>Rocket Launchers</h5>
        <div className="wishlist-table ">
          <div className="column">
            <h5 className="prio-1">DPS</h5>
            <ol className="list-with-chars-inline">
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="laplace"
                  showLabel
                  enablePopover
                  showTreasure
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="scarlet-black-shadow"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="emilia"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="a2"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="vesti"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="power"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-3">Support</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="tia"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="biscuit"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noise"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="rapunzel"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noah"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-2">PVP</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="emilia"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noise"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="rapunzel"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="noah"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="jackal"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="centi"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="anis"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="miracle-fairy-anne"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="bay"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="a2"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="rumani"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="tia"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="quiry"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="power"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <h5>Sub-Machine Guns</h5>
        <div className="wishlist-table ">
          <div className="column">
            <h5 className="prio-1">DPS</h5>
            <ol className="list-with-chars-inline">
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="quency-escape-queen"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="epinel"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="soline"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-3">Support</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="liter"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="mast"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="volume"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="alice-wonderland-bunny"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="miranda"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="mica-snow-buddy"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
          <div className="column">
            <h5 className="prio-2">PVP</h5>
            <ol>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="quency-escape-queen"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="mica-snow-buddy"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="liter"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="makima"
                  showLabel
                  enablePopover
                />
              </li>
              <li>
                <NikkeCharacter
                  mode="inline"
                  slug="nero"
                  showLabel
                  enablePopover
                />
              </li>
            </ol>
          </div>
        </div>
        <SectionHeader title="Combining" />
        <p>
          After you have equipped dolls on all the Nikkes you want in a certain
          weapon type with R/SR dolls, you may start using that weapon type's
          doll into Combine for more materials to upgrade your dolls, or for a
          chance to get more SR dolls.In Combine, you can give 4 R dolls for a
          chance to get an SR doll. But most probably, you will receive Purple
          or Gold Maintenance Kits.
        </p>
        <StaticImage
          src="../../../images/nikke/collection/collection_combine.webp"
          alt="Collection Combining"
        />
        <SectionHeader title="Upgrade Priority" />
        <p>
          Upgrading Dolls isn't the same as Equipping Dolls. It imposes
          different priorities because the buffs that they give vary in
          usefulness across weapon types. You may also consider upgrading Dolls
          solely for CP padding, albeit that one might be too resource-intensive
          for the average player. CP padding will improve your Campaign and PvP
          performance, but if you factor in the cost, it isn't really worth the
          hassle and investment.
        </p>
        <p>
          Back to the topic, Dolls offer significantly more potent buffs for SR,
          RL, and SG as they increase the Total Damage Multiplier. AR can also
          benefit from the Core Damage Multiplier, but not all enemies have an
          exposed core. On the other hand, MG has it worse with just a tiny bit
          of Max Ammo increase, which is heavily diluted by Max Ammo OL. Thus,
          for MG, the buff is insubstantial, and upgrading it will not amplify
          damage output as much as upgrading an SG or SR Doll.
        </p>
        <p>
          For more info about these weapon-specific buffs, visit the homepage of
          this guide series.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={2}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Collection Items - Intro"
            link="/nikke/guides/collection-intro"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_coll.jpg"
                alt="Collection Items - Intro"
              />
            }
          />
        </Row>
        <p>
          Therefore, we can conclude that there are 3 upgrading paths, depending
          on your goal:
        </p>
        <ul>
          <li>
            Are you aiming to maximize your damage output in Raids and other PvE
            content?
          </li>
          <li>Are you aiming to pad your CP for Campaign and PvP advantage?</li>
          <li>Are you planning to utilize a Treasure/Favorite Item?</li>
        </ul>
        <p>
          The answer for No. 2 is relatively simple, so we'll answer that first.
          Basically, what you want to do is equip and upgrade Dolls on Nikkes
          you normally use for Campaign and PvP. We are not going to suggest any
          Nikkes here because everyone prefers to use different Nikkes for
          different content. However, since Dolls give stat boost, damage
          reduction, as well as cover HP increase, you may want to prioritize
          DPS first and units who need to absorb tons of damage, such as Noah,
          Noise, or Rapunzel.{' '}
        </p>
        <p>
          Now, for maximizing damage output, it is honestly a little complicated
          because it also depends on the type of content, but for most people it
          would be Raids. Here are some tips to help you decide whose Doll to
          upgrade first:
        </p>
        <ul>
          <li>
            Some DPS may perform significantly better than other DPS in a Raid.
            Prioritize them first. For example, against Gravedigger, upgrading
            Red Hood's and Soda: Twinking Bunny's Dolls was a great idea.
          </li>
          <li>
            What we are saying here is that upgrade Dolls only after you know
            who you will use when a Raid is active. Do NOT upgrade them without
            knowing who is going to excel. Also prioritize DPS that has
            consistent performance across Raids or has shown a record of doing
            so. This includes SBS and Alice.
          </li>
          <li>
            Now, for weapon types, prioritize SR/RL/SG &gt; AR &gt; SMG/MG.
            Currently, we do not really have any powerful SMG DPS, so there is
            no reason to upgrade.
          </li>
        </ul>
        <p>
          What if I wanted to maximize my damage output AND do CP padding at the
          same time? Well, you should then prioritize units that are used in all
          content. For example, we use Red Hood in Raids, Campaign, and even
          PvP. Upgrading her Doll is an economically wise choice.
        </p>
        <p>
          Finally, the last question is in-arguably the most important. Why is
          that? Well, if you are planning to get Treasure for someone, do NOT
          even think about CP padding or upgrading Dolls for stat/damage boost
          beyond R 15 / SR 5. Your income of Maintenance Kits is very low. You
          should save them for upgrading your SR Doll to Level 15 for that
          Treasure Nikke instead.
        </p>
        <SectionHeader title="Top Picks to spend your maintenance kits on!" />
        <h5>PvE</h5>
        <ul>
          <li>
            <NikkeCharacter mode="inline" slug="red-hood" enablePopover />:
            Hands down the best unit to upgrade in our opinion. The best unit in
            the game, period.
          </li>
          <li>
            <NikkeCharacter mode="inline" slug="alice" enablePopover />: Alice
            is simply the best DPS, and also benefits the most from Dolls
            compared to other SRs due to her higher base Charge Multiplier
            (350%). For us, she fits in right in the Priority #2 spot!
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="scarlet-black-shadow"
              enablePopover
            />
            : Simply insane DPS output. Cannot have a raid without her, and with
            the recent CP changes she shines even brighter in Campaign as well!
            Even in PvP she is one of the better options to use as main B3! SBS
            sits comfortably in the top 3 units to upgrade your commander dolls
            on!
          </li>
        </ul>
        <h5>PvE Tier List</h5>
        <StaticImage
          src="../../../images/nikke/collection/collection_pve_tl.webp"
          alt="Collection PVE Tierlist"
        />
        <h5>Explaining the other Tiers </h5>
        <p>
          Favorite Items: If you just want to focus on unlocking the newer
          version of the units, the order of priority is given from left to
          right.
        </p>
        <ul>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="exia"
              enablePopover
              showTreasure
            />{' '}
            is very important for doing better in electric weak raids and also
            against Mirror Container, in both Campaign and in A.I.{' '}
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="laplace"
              enablePopover
              showTreasure
            />{' '}
            is an overall decent unit for campaign, will shine in Iron Weak
            Raids, and is the top PvP pick, above all other treasure units.
            However, her Campaign and Raid use is more or less restricted for
            Iron Weak stages in the overall meta.
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="viper"
              enablePopover
              showTreasure
            />{' '}
            is a unit for Water Weak Raids.{' '}
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="diesel"
              enablePopover
              showTreasure
            />{' '}
            will help you progress in Elysion Tower, or will help incomplete
            rosters do better in Raids.{' '}
          </li>
          <li>
            <NikkeCharacter
              mode="inline"
              slug="frima"
              enablePopover
              showTreasure
            />{' '}
            is a unit for the future. The True Damage team has not come along
            quite yet.{' '}
          </li>
        </ul>
        <p>
          <strong>Top DPS Picks</strong>
        </p>
        <p>
          The top pick here for raids specifically are the SG team DPS.
          Investing in Maid Privaty and B.Soda can allow you to run healer-less
          SG teams in Raids!
        </p>
        <p>
          All of the remaining DPS units are paired with each other according to
          their element. Left to Right in priority in their elements. e.g. Fire
          has Asuka &gt; Mod, while Iron has SW &gt; Maxwell.
        </p>
        <p>
          <strong>Top Supports</strong>
        </p>
        <p>
          If you are trying to focus it all into one team - to perhaps get stage
          9 for an Anomaly Interception, or to increase your combat power in
          Campaign - these are the top picks for Supports.
        </p>
        <h5>PvP Tier List</h5>
        <p>
          This is for those PvP hyper fans who are waiting for Champions Arena
          details.
        </p>
        <ul>
          <li>Priority of this Tier List is: Left to Right, Top to Bottom.</li>
          <li>
            Exception: Rapunzel, xAnne, Nero and Bay can be done after some of
            the good DPS are brought up.{' '}
          </li>
        </ul>
        <StaticImage
          src="../../../images/nikke/collection/collection_pvp_tl.webp"
          alt="Collection PVP Tierlist"
        />
      </div>
      <SectionHeader title="Other guides in the series" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Collection Items - Intro"
          link="/nikke/guides/collection-intro"
          image={
            <StaticImage
              src="../../../images/nikke/categories/category_coll.jpg"
              alt="Collection Items - Intro"
            />
          }
        />
        <CategoryCard
          title="Collection Items - Upgrade Optimization"
          link="/nikke/guides/collection-dolls"
          image={
            <StaticImage
              src="../../../images/nikke/categories/category_dolls.jpg"
              alt="Collection Items - Upgrade Optimization"
            />
          }
        />
        <CategoryCard
          title="Collection Items - Dispatch"
          link="/nikke/guides/collection-dispatch"
          image={
            <StaticImage
              src="../../../images/nikke/categories/category_dispatch.jpg"
              alt="Collection Items - Dispatch"
            />
          }
        />
      </Row>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesCollectionPriority;

export const Head: React.FC = () => (
  <Seo
    title="Collection Items - Priority | NIKKE | Prydwen Institute"
    description="A priority guide for the Collection Items to help you decide who to equip the Dolls on."
  />
);
